<template>
  <v-container fluid @keyup.escape="close()">
    <v-row justify="center">
      <v-col cols="10" sm="8" md="6" lg="4">
        <v-card>
          <v-toolbar :color="actionColor" dark>
            <v-toolbar-title>{{ $helper.tRoute($route.name) }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-icon @click="close" id="exit" ref="exit">mdi-close</v-icon>
          </v-toolbar>
          <v-card-text>
            <v-form id="form" :readonly="readonly" ref="form">
              <v-text-field
                v-model="form.name"
                :clearable="!readonly"
                id="name"
                :label="$t(`Cols.${table}.name`) + star"
                maxlength="50"
                ref="name"
                :rules="[required]"
                validate-on-blur
              ></v-text-field>
              <v-autocomplete
                v-model="form.customer_type_id"
                @focus="autocompleteItems('customer_type_id', 'customer_types')"
                :clearable="!readonly"
                id="customer_type_id"
                :items="items.customer_type_id"
                :label="$t(`Cols.${table}.customer_type_id`)"
                ref="customer_type_id"
                :rules="[]"
              ></v-autocomplete>
              <v-text-field
                v-model="form.phone"
                :clearable="!readonly"
                id="phone"
                :label="$t(`Cols.${table}.phone`)"
                maxlength="20"
                ref="phone"
                :rules="[]"
                validate-on-blur
              ></v-text-field>
              <v-textarea
                v-model="form.other_phone"
                auto-grow
                :clearable="!readonly"
                id="other_phone"
                :label="$t(`Cols.${table}.other_phone`)"
                maxlength="1024"
                ref="other_phone"
                rows="2"
                :rules="[]"
                validate-on-blur
              ></v-textarea>
              <v-text-field
                v-model="form.mail"
                :clearable="!readonly"
                id="mail"
                :label="$t(`Cols.${table}.mail`)"
                maxlength="100"
                ref="mail"
                :rules="[]"
                validate-on-blur
              ></v-text-field>
              <v-text-field
                v-model="form.instagram"
                :clearable="!readonly"
                id="instagram"
                :label="$t(`Cols.${table}.instagram`)"
                maxlength="50"
                ref="instagram"
                :rules="[]"
                validate-on-blur
              ></v-text-field>
              <v-text-field
                v-model="form.facebook"
                :clearable="!readonly"
                id="facebook"
                :label="$t(`Cols.${table}.facebook`)"
                maxlength="50"
                ref="facebook"
                :rules="[]"
                validate-on-blur
              ></v-text-field>
              <v-text-field
                v-model="form.twitter"
                :clearable="!readonly"
                id="twitter"
                :label="$t(`Cols.${table}.twitter`)"
                maxlength="50"
                ref="twitter"
                :rules="[]"
                validate-on-blur
              ></v-text-field>
              <v-text-field
                v-model="form.other_social_networks"
                :clearable="!readonly"
                id="other_social_networks"
                :label="$t(`Cols.${table}.other_social_networks`)"
                maxlength="50"
                ref="other_social_networks"
                :rules="[]"
                validate-on-blur
              ></v-text-field>
              <v-select
                v-model="form.sex"
                @focus="selectItems('sex')"
                :clearable="!readonly"
                id="sex"
                :items="items.sex"
                :label="$t(`Cols.${table}.sex`)"
                ref="sex"
                :rules="[]"
              ></v-select>
              <v-text-field
                v-model="form.birthday"
                :clearable="!readonly"
                id="birthday"
                :label="$t(`Cols.${table}.birthday`)"
                ref="birthday"
                :rules="[]"
                type="datetime"
              ></v-text-field>
              <v-combobox
                v-model="form.profession"
                @focus="comboItems('profession')"
                :clearable="!readonly"
                id="profession"
                :items="items.profession"
                :label="$t(`Cols.${table}.profession`)"
                maxlength="35"
                ref="profession"
                :return-object="false"
                :rules="[]"
              ></v-combobox>
              <v-autocomplete
                v-model="form.sales_person_id"
                @focus="autocompleteItems('sales_person_id', 'sales_persons')"
                :clearable="!readonly"
                id="sales_person_id"
                :items="items.sales_person_id"
                :label="$t(`Cols.${table}.sales_person_id`)"
                ref="sales_person_id"
                :rules="[]"
              ></v-autocomplete>
              <v-combobox
                v-model="form.sector"
                @focus="comboItems('sector')"
                :clearable="!readonly"
                id="sector"
                :items="items.sector"
                :label="$t(`Cols.${table}.sector`)"
                maxlength="25"
                ref="sector"
                :return-object="false"
                :rules="[]"
              ></v-combobox>
              <v-combobox
                v-model="form.marketing_channel"
                @focus="comboItems('marketing_channel')"
                :clearable="!readonly"
                id="marketing_channel"
                :items="items.marketing_channel"
                :label="$t(`Cols.${table}.marketing_channel`)"
                maxlength="25"
                ref="marketing_channel"
                :return-object="false"
                :rules="[]"
              ></v-combobox>
              <v-textarea
                v-model="form.address"
                auto-grow
                :clearable="!readonly"
                id="address"
                :label="$t(`Cols.${table}.address`)"
                maxlength="1024"
                ref="address"
                rows="2"
                :rules="[]"
                validate-on-blur
              ></v-textarea>
              <v-text-field
                v-model="form.tax_name"
                :clearable="!readonly"
                id="tax_name"
                :label="$t(`Cols.${table}.tax_name`)"
                maxlength="100"
                ref="tax_name"
                :rules="[]"
                validate-on-blur
              ></v-text-field>
              <v-text-field
                v-model="form.tax_code"
                :clearable="!readonly"
                id="tax_code"
                :label="$t(`Cols.${table}.tax_code`)"
                maxlength="20"
                ref="tax_code"
                :rules="[]"
                validate-on-blur
              ></v-text-field>
              <v-textarea
                v-model="form.tax_domicile"
                auto-grow
                :clearable="!readonly"
                id="tax_domicile"
                :label="$t(`Cols.${table}.tax_domicile`)"
                maxlength="1024"
                ref="tax_domicile"
                rows="2"
                :rules="[]"
                validate-on-blur
              ></v-textarea>
              <v-text-field
                v-model="form.emergency_phone"
                :clearable="!readonly"
                id="emergency_phone"
                :label="$t(`Cols.${table}.emergency_phone`)"
                maxlength="20"
                ref="emergency_phone"
                :rules="[]"
                validate-on-blur
              ></v-text-field>
              <v-text-field
                v-model="form.emergency_contact"
                :clearable="!readonly"
                id="emergency_contact"
                :label="$t(`Cols.${table}.emergency_contact`)"
                maxlength="35"
                ref="emergency_contact"
                :rules="[]"
                validate-on-blur
              ></v-text-field>
              <v-combobox
                v-model="form.emergency_relationship"
                @focus="comboItems('emergency_relationship')"
                :clearable="!readonly"
                id="emergency_relationship"
                :items="items.emergency_relationship"
                :label="$t(`Cols.${table}.emergency_relationship`)"
                maxlength="20"
                ref="emergency_relationship"
                :return-object="false"
                :rules="[]"
              ></v-combobox>
              <v-textarea
                v-model="form.medical_alert"
                auto-grow
                :clearable="!readonly"
                id="medical_alert"
                :label="$t(`Cols.${table}.medical_alert`)"
                maxlength="1024"
                ref="medical_alert"
                rows="2"
                :rules="[]"
                validate-on-blur
              ></v-textarea>
              <v-select
                v-model="form.blood_type"
                @focus="selectItems('blood_type')"
                :clearable="!readonly"
                id="blood_type"
                :items="items.blood_type"
                :label="$t(`Cols.${table}.blood_type`)"
                ref="blood_type"
                :rules="[]"
              ></v-select>
              <v-textarea
                v-model="form.allergies"
                auto-grow
                :clearable="!readonly"
                id="allergies"
                :label="$t(`Cols.${table}.allergies`)"
                maxlength="1024"
                ref="allergies"
                rows="2"
                :rules="[]"
                validate-on-blur
              ></v-textarea>
              <v-checkbox
                v-model="form.surgery"
                id="surgery"
                :label="$t(`Cols.${table}.surgery`)"
                ref="surgery"
              ></v-checkbox>
              <v-text-field
                v-model="form.surgery_description"
                :clearable="!readonly"
                id="surgery_description"
                :label="$t(`Cols.${table}.surgery_description`)"
                maxlength="35"
                ref="surgery_description"
                :rules="[]"
                validate-on-blur
              ></v-text-field>
              <v-checkbox
                v-model="form.practiced_sport"
                id="practiced_sport"
                :label="$t(`Cols.${table}.practiced_sport`)"
                ref="practiced_sport"
              ></v-checkbox>
              <v-text-field
                v-model="form.sport_practiced"
                :clearable="!readonly"
                id="sport_practiced"
                :label="$t(`Cols.${table}.sport_practiced`)"
                maxlength="35"
                ref="sport_practiced"
                :rules="[]"
                validate-on-blur
              ></v-text-field>
              <v-text-field
                v-model="form.other_sports"
                :clearable="!readonly"
                id="other_sports"
                :label="$t(`Cols.${table}.other_sports`)"
                maxlength="20"
                ref="other_sports"
                :rules="[]"
                validate-on-blur
              ></v-text-field>
              <v-text-field
                v-model="form.time_not_exercizing"
                :clearable="!readonly"
                id="time_not_exercizing"
                :label="$t(`Cols.${table}.time_not_exercizing`)"
                maxlength="15"
                ref="time_not_exercizing"
                :rules="[]"
                validate-on-blur
              ></v-text-field>
              <v-checkbox
                v-model="form.smokes"
                id="smokes"
                :label="$t(`Cols.${table}.smokes`)"
                ref="smokes"
              ></v-checkbox>
              <v-text-field
                v-model="form.customer_since"
                :clearable="!readonly"
                id="customer_since"
                :label="$t(`Cols.${table}.customer_since`) + star"
                ref="customer_since"
                :rules="[required]"
                type="date"
              ></v-text-field>
              <v-textarea
                v-model="form.note"
                auto-grow
                :clearable="!readonly"
                id="note"
                :label="$t(`Cols.${table}.note`)"
                maxlength="1024"
                ref="note"
                rows="2"
                :rules="[]"
                validate-on-blur
              ></v-textarea>
              <v-text-field
                v-model="form.photo_file"
                :clearable="!readonly"
                id="photo_file"
                :label="$t(`Cols.${table}.photo_file`)"
                maxlength="255"
                ref="photo_file"
                :rules="[]"
                validate-on-blur
              ></v-text-field>
            </v-form>
            <v-alert v-model="showWarning" dense outlined type="info">{{
              warning
            }}</v-alert>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              v-if="action == 'create' || action == 'clone'"
              @click="store"
              :color="actionColor"
              dark
              id="save"
              >{{ $t("save") }}
            </v-btn>
            <v-btn
              v-if="action == 'edit'"
              @click="update"
              :color="actionColor"
              dark
              id="update"
              >{{ $t("update") }}
            </v-btn>
            <v-btn
              v-if="action == 'delete'"
              @click="destroy"
              :color="actionColor"
              dark
              id="delete"
              >{{ $t("delete") }}
            </v-btn>
            <v-btn @click="close" :color="actionColor" dark id="close">{{
              $t("close")
            }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";

const TABLE = "customers";

export default {
  data() {
    return {
      action: "show",
      autocompletes: ["customer_type_id", "sales_person_id"],
      form: {},

      formats: {
        birthday: "DateTime",
        customer_since: "Date",
      },

      items: {
        customer_type_id: [{}],
        sex: [{}],
        profession: [{}],
        sales_person_id: [{}],
        sector: [{}],
        marketing_channel: [{}],
        emergency_relationship: [{}],
        blood_type: [{}],
      },


      loading: false,
      required: (v) => !!v || this.$t("fieldRequired"),

      selects: ["sex", "blood_type"],
      table: TABLE,
    };
  },

  computed: {
    actionColor() {
      return this.$settings.actionColors[this.action];
    },

    moneySymbol() {
      return this.$helper.moneySymbol();
    },

    readonly() {
      return this.action == "delete" || this.action == "show";
    },

    showWarning() {
      return this.warning !== null;
    },

    star() {
      return this.readonly ? "" : " *";
    },

    warning() {
      if (this.action == "delete" && this.$te(`delete_${TABLE}_warning`))
        return this.$t(`delete_${TABLE}_warning`);

      return null;
    },
  },

  created() {
    this.loading = true;
    this.action = this.$route.name.split(".")[1];

    this.$api
      .show(TABLE, this.$route.params.id, this.action)
      .then((response) => this.setForm(response.data.data[0]))
      .catch((e) => this.error(e))
      .finally(() => (this.loading = false));
  },

  methods: {
    ...mapActions({
      alertError: "app/alertError",
      alertSuccess: "app/alertSuccess",
      alertWarning: "app/alertWarning",
    }),

    actionSuccess() {
      return this.$t(this.action + "Success", [this.$helper.tTable(TABLE)]);
    },

    autocompleteItems(name, path) {
      if (!this.readonly) {
        this.loading = true;
        this.$api
          .list(path)
          .then((response) => (this.items[name] = response.data))
          .catch((e) => this.error(e))
          .finally(() => (this.loading = false));
      }
    },

    close() {
      this.$router.go(-1);
    },

    comboItems(name) {
      if (!this.readonly) {
        this.loading = true;
        this.$api
          .combo(TABLE, name)
          .then((response) => (this.items[name] = response.data))
          .catch((e) => this.error(e))
          .finally(() => (this.loading = false));
      }
    },

    destroy() {
      this.loading = true;

      this.$api
        .destroy(TABLE, this.form.id)
        .then(() => this.success())
        .catch((e) => this.error(e))
        .finally(() => (this.loading = false));
    },

    error(e) {
      if (this.$helper.errorStatus(e) == 403) this.$router.replace({ name: "forbidden" });

      let error = this.$helper.error(e);
      let message = "";

      if (error == "invalidData") {
        this.$helper.setFieldErrors(e, this);
        message = this.$t(error);
      } else if (error == "destroyError") {
        message = this.$t(`destroy_${TABLE}_error`);
      } else if (this.$te(error)) {
        message = this.$t(error, [this.$helper.tTable(TABLE)]);
      } else if (this.$helper.errorStatus(e) == "404") {
        message = this.$t("doesNotExist", [this.$helper.tTable(TABLE)]);
      } else {
        message =
          this.$t(this.action + "Error", [this.$helper.tTable(TABLE)]) +
          this.$helper.error(e);
      }

      this.alertError(message);
    },

    numeric(v) {
      if (v == undefined) return true; //Avoid warning "Error in beforeMount hook..."

      return /^[-+]?([\d,]*)?(\.\d*?)?$/.test(v.trim()) || this.$t("validNumber");
    },

    selectItems(name) {
      if (!this.readonly) {
        this.items[name] = this.$helper.selectItems(name);
      }
    },

    setForm(item) {
      if (JSON.stringify(item) === JSON.stringify({}))
        return this.$t("doesNotExist", [this.$helper.tTable(TABLE)]);

      this.$helper.formatItem(item, this.formats);

      this.form = item;

      this.autocompletes.forEach((name) => {
        this.items[name] = [{ text: item[name.slice(0, -3)], value: item[name] }];
      });

      this.selects.forEach((name) => {
        if (item[name]) {
          let text = this.$helper.formatSelect(item[name], name);
          this.items[name] = [{ text: text, value: item[name] }];
        }
      });

      this.$refs.form.resetValidation();

      this.$refs.exit.$el.focus();
    },

    store() {
      if (this.validForm()) {
        this.loading = true;
        this.$api
          .store(TABLE, this.form)
          .then(() => this.success())
          .catch((e) => this.error(e))
          .finally(() => (this.loading = false));
      }
    },

    success() {
      this.alertSuccess(this.actionSuccess());
      this.close();
    },

    update() {
      if (this.validForm()) {
        this.loading = true;
        this.$api
          .update(TABLE, this.form.id, this.form)
          .then(() => this.success())
          .catch((e) => this.error(e))
          .finally(() => (this.loading = false));
      }
    },

    validForm() {
      if (this.$refs.form.validate()) return true;

      this.alertWarning(this.$t("invalidData"));
    },
  },
};
</script>
